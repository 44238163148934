
import { defineComponent, ref, computed } from 'vue';
import DashboardContentHeader from '@/components/common/DashboardContentHeader.vue';
import GenericTable from '@/components/common/tables/genericTable/GenericTable.vue';
import EventModal from '@/views/dashboard/statusboard/EventModal.vue';
import MultiSelect from '@/components/ui/MultiSelect.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import Card from '@/components/common/layouts/Card.vue';
import { store, ActionType } from '@/plugins/store';
import { Case, Project } from '@/types';

export default defineComponent({
    components: { DashboardContentHeader, Card, CustomInput, MultiSelect, GenericTable, EventModal },
    created() {
        store.dispatch(ActionType.getProjects);
    },
    setup() {
        const projects = computed(() =>
            store.state.projects !== null
                ? store.state.projects
                      .map((project) => {
                          return { name: project.sProject, value: project.sProject };
                      })
                      .sort((a, b) => a.name.localeCompare(b.name))
                : []
        );
        const project = ref([] as String[]);
        const search = ref('');
        return {
            eventHeaders: [
                { headerName: 'Project', field: 'project', sortable: true },
                { headerName: 'Case', field: 'name', sort: 'desc', sortable: true },
                { headerName: 'Event', field: 'event', sortable: true },
                { headerName: 'Time', field: 'time', sortable: true },
                { headerName: 'Comment', field: 'comment', sortable: true },
            ],
            events: computed(() =>
                (store.state.projects ?? [])
                    .filter((item: Project) => project.value.includes(item.sProject))
                    .reduce((acc: any[], cur: Project) => {
                        return acc.concat(
                            (cur.cases ?? []).reduce((accCase: any[], item: Case) => {
                                return accCase.concat(
                                    item.events.map((event) => {
                                        return { project: cur.sProject, event: event.evtDescription, name: item.sTitle, time: event.dt, comment: event.s };
                                    })
                                );
                            }, [])
                        );
                    }, [])
                    .filter((item) => {
                        return search.value === '' || item.comment.toLowerCase().indexOf(search.value.toLowerCase()) !== -1 || item.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1;
                    })
            ),
            projects,
            project,
            search,
            selectedEvent: ref({}),
        };
    },
    watch: {
        project() {
            const projects = (store.state.projects ?? []).filter((project: Project) => this.project.includes(project.sProject));
            store.dispatch(ActionType.getCases, { form: { projects } });
        },
    },
    methods: {
        eventClicked(item: any, open: CallableFunction) {
            this.selectedEvent = item;
            open();
        },
    },
});
