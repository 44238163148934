import { MutationTree } from 'vuex';
import { State } from '@/plugins/store/state';
import { Project } from '@/types';

export enum MutationType {
    SetProjects = 'SET_PROJECTS',
}
const mutationObject = {
    [MutationType.SetProjects](state: State, projects: Project[]) {
        state.projects = projects;
    },
};

/* Boilerplate for type safety */
export type Mutations = typeof mutationObject;
export const mutations: MutationTree<State> & Mutations = mutationObject;
