
import { defineComponent, computed } from 'vue';
import Modal from '@/components/ui/Modal.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import InfoTable from '@/components/common/tables/InfoTable.vue';

export default defineComponent({
    emits: [],
    components: { Modal, CustomButton, InfoTable },
    props: {
        event: {
            type: Object as () => { project: string; event: string; name: string; time: string; comment: string },
            required: true,
        },
    },
    setup(props) {
        return {
            itemData: computed(() => [
                { name: 'Project', id: 'project', value: props.event.project },
                { name: 'Event', id: 'event', value: props.event.event },
                { name: 'Name', id: 'name', value: props.event.name },
                { name: 'Time', id: 'time', value: props.event.time },
                { name: 'Comment', id: 'comment', value: props.event.comment },
            ]),
        };
    },
});
