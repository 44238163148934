
import { defineComponent, computed } from 'vue';
import { routes } from '@/plugins/router/routes';
import { auth } from '@/plugins/firebase';

export default defineComponent({
    data() {
        return {
            sidebarRoutes: computed(() => routes.find((currentRoute) => currentRoute.meta?.isDashboardRoute)!.children!.filter((currentRoute) => currentRoute.meta?.appearsInSidebar)),
        };
    },
    methods: {
        async onLogoutClicked() {
            const logout = window.confirm('Are you sure you want to log out?');
            if (logout) {
                await auth.signOut();
                this.$router.push({ name: 'Login' });
            }
        },
    },
});
