import { createToastInterface } from 'vue-toastification';
import LoadingIndicator from '@/plugins/store/api/LoadingIndicator';
import { options } from '@/plugins/toastification';

export default class ApiRequest {
    private static toast = createToastInterface(options);

    static async send<T>(callback: Promise<any>): Promise<T> {
        LoadingIndicator.start();
        try {
            const result = await callback;
            return result;
        } catch (error: any) {
            ApiRequest.toast.error('An unexpected API error has occured');
            console.log(error);
            throw error;
        } finally {
            LoadingIndicator.done();
        }
    }
}
