
import { defineComponent, ref, computed } from 'vue';
import ClickOutside from '@/util/directives/ClickOutside';
import CustomInput from '@/components/ui/CustomInput.vue';

export default defineComponent({
    emits: ['update:value'],
    components: { CustomInput },
    directives: { ClickOutside },
    props: {
        label: {
            type: String,
            default: '',
        },
        items: {
            type: Array as () => { name: string; value: string }[],
            default: [],
        },
        value: {
            type: Array,
            required: true,
        },
        itemText: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const search = ref('');
        console.log('here');
        return {
            expandDropdown: ref(false),
            search,
            filteredItems: computed(() =>
                props.items.filter((item: { name: string; value: string }) => {
                    return search.value === '' || item.value.toLowerCase().indexOf(search.value.toLowerCase()) !== -1;
                })
            ),
        };
    },
    computed: {
        numberOfSelectedItems() {
            return this.value.length;
        },
    },
    methods: {
        onSelectClicked() {
            this.expandDropdown = !this.expandDropdown;
        },
        onItemClicked(item: { [key: string]: number | string }) {
            const newValue = [...this.value];
            const itemSelectedIndex = newValue.indexOf(item[this.itemValue]);
            if (itemSelectedIndex === -1) {
                newValue.push(item[this.itemValue]);
                this.$emit('update:value', newValue);
            } else {
                newValue.splice(itemSelectedIndex, 1);
                this.$emit('update:value', newValue);
            }
        },
    },
});
