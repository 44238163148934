import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/plugins/store/state';
import { Mutations, MutationType } from '@/plugins/store/mutations';
import ApiRequest from '@/plugins/store/api/ApiRequest';
import { Project, Case } from '@/types';
import FirebaseClient, { Collection } from '@/plugins/store/api/FirebaseClient';

export enum ActionType {
    getProjects = 'GET_PROJECTS',
    getCases = 'GET_CASES',
}

const actionsObject = {
    async [ActionType.getProjects](store: ActionAugments) {
        let projects = store.state.projects;
        if (projects === null) {
            projects = await ApiRequest.send<Project[]>(FirebaseClient.getCollection(Collection.projects));
            store.commit(
                MutationType.SetProjects,
                projects.map((project) => {
                    project.cases = null;
                    return project;
                })
            );
        }
        return projects;
    },
    async [ActionType.getCases](store: ActionAugments, args: { form: { projects: Project[] } }) {
        Promise.all(
            args.form.projects.map((project) => {
                if (project.cases === null) {
                    return ApiRequest.send<Case[]>(FirebaseClient.getCollection(Collection.projects, project.id, 'cases')).then((cases) => {
                        project.cases = cases;
                    });
                }
                return project;
            })
        );
    },
};

/* Boilerplate for type safety */
export type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
    commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};
export type Actions = typeof actionsObject;
export const actions: ActionTree<State, State> & Actions = actionsObject;
