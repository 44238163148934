import { RouteRecordRaw } from 'vue-router';
import Dashboard from '@/views/dashboard/Index.vue';
import StatusBoard from '@/views/dashboard/statusboard/Index.vue';
import Auth from '@/views/auth/Index.vue';
import Login from '@/views/auth/Login.vue';

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Auth',
        component: Auth,
        redirect: '/auth/login',
        children: [
            {
                path: '/auth/login',
                name: 'Login',
                component: Login,
                meta: { skipAuthCheck: true },
            },
        ],
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        redirect: '/dashboard',
        meta: {
            isDashboardRoute: true,
        },
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: StatusBoard,
                meta: {
                    appearsInSidebar: true,
                    sidebarText: 'Dashboard',
                    sidebarIcon: '/icons/nav_users_active.svg',
                },
            },
        ],
    },
];
