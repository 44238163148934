
import { defineComponent, onUnmounted, watch, reactive } from 'vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { AgGridVue } from 'ag-grid-vue3';
import { ColDef, GridOptions } from 'ag-grid-community';
import GenericTableCell from '@/components/common/tables/genericTable/cellRenderers/GenericCellRenderer.vue';
import ActionCellRenderer from '@/components/common/tables/genericTable/cellRenderers/ActionCellRenderer.vue';
import useDebouncedWindowWidth from '@/util/composables/useDebouncedWindowWidth';

export default defineComponent({
    emits: ['item-clicked'],
    components: { AgGridVue, GenericTableCell, ActionCellRenderer },
    props: {
        headers: {
            type: Array as () => ColDef[],
            default: [],
        },
        items: {
            type: Array as () => any[],
            default: [],
        },
        options: {
            type: Object,
            default: {},
        },
        width: {
            type: String,
            default: 'width: calc(100vw - 20rem)',
        },
    },
    setup(props) {
        const debouncedWindowWidth = useDebouncedWindowWidth(250);

        window.addEventListener('resize', debouncedWindowWidth.resizeHandler);
        onUnmounted(() => {
            window.removeEventListener('resize', debouncedWindowWidth.resizeHandler);
        });

        const gridOptions = reactive({
            suppressCellSelection: true,
            headerHeight: 75,
            ...props.options,
        }) as GridOptions;

        watch(debouncedWindowWidth.windowWidth, () => {
            gridOptions.api?.sizeColumnsToFit();
        });

        return { gridOptions };
    },
    computed: {
        styledHeaders(): ColDef[] {
            return this.$props.headers.map((currentHeader) => {
                return {
                    ...currentHeader,
                    cellRendererFramework: currentHeader.cellRendererFramework || 'GenericTableCell',
                };
            });
        },
    },
    methods: {
        onGridReady() {
            this.gridOptions.api?.sizeColumnsToFit();
            this.gridOptions.api?.setDomLayout('autoHeight');
        },
        onSelectionChanged() {
            const selectedRows = this.gridOptions.api?.getSelectedRows();
            this.gridOptions.api?.deselectAll();
            if (selectedRows && selectedRows.length) {
                this.$emit('item-clicked', selectedRows[0]);
            }
        },
    },
});
